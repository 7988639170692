import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterModel } from 'src/app/platform/modules/core/modals/common-model';
import { CommonService } from 'src/app/platform/modules/core/services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageNgoService {
  getAllURL = 'api/Organization/GetNgos';
  getAllCausesURL = 'api/Organization/GetCauses';
  getByIdURL = "api/Organization/GetNgoById";
  getNgoByIdForLogin = "api/Organization/GetNgoByUserId";
  createURL = "api/Organization/SaveNgo";
  UpdateToggleIsActiveURL = "api/Organization/UpdateTogglesIsActive";
  UpdateTogglesIsDeleteURL = "api/Organization/UpdateTogglesIsDeleted";
  deleteURL = "api/Organization/DeleteNgo";
  getMasterDataURL = 'api/MasterData/MasterDataByName';
  getRewardPointsDataURL = 'api/Organization/GetAllRewardPoints';

  constructor(private commonService: CommonService,
  ) { }


  getAll() {
    let url = this.getAllURL;
    return this.commonService.getAll(url, {});
  }

  getAllRewardPoints(ngoId: number, selectedOfficeLocations: any, selectedDates: any,selectedyear:any) {
    let url = this.getRewardPointsDataURL;
    return this.commonService.getAll(url + "?userId=" + ngoId +
      "&selectedCompany=" + selectedOfficeLocations + "&selectedDates=" + selectedDates + "&selectedyear="+selectedyear, {});
  }

  getAllCauses() {
    let url = this.getAllCausesURL;
    return this.commonService.getAll(url, {});
  }


  getById(id: number) {
    return this.commonService.getById(this.getByIdURL + "?Id=" + id, {});
  }

  NgoByIdForLogin(id: number) {
    return this.commonService.getById(this.getNgoByIdForLogin + "?Id=" + id, {});
  }

  deleteNgo(id: number) {
    return this.commonService.put(this.deleteURL + "?Id=" + id, {});
  }

  getMasterData(masterData: any): Observable<any> {
    return this.commonService.post(this.getMasterDataURL, masterData);
  }
  save(agencyModel: any) {
    return this.commonService.post(this.createURL, agencyModel);
  }

  updateTogglesIsActive(toggleUpdateModel: any) {
    return this.commonService.post(this.UpdateToggleIsActiveURL, toggleUpdateModel);
  }

  updateTogglesIsDeleted(toggleUpdateModel: any) {
    return this.commonService.post(this.UpdateTogglesIsDeleteURL, toggleUpdateModel);
  }
}
